import { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './pages/users/partials/auth/protected.route';
import { isStockStage } from './assets/javascripts/helper';

const ErrorsIndex = lazy(() => import('./pages/errors'));
const LandingIndex = lazy(() => import('./pages/landing/index_ts'));
const UsersIndex = lazy(() => import('./pages/users/index_ts'));
const DashboardIndex = lazy(() => import('./pages/dashboard/index_ts'));
const ItemsNew = lazy(() => import('./pages/items/new'));
const ItemsEdit = lazy(() => import('./pages/items/edit'));
const PriceIndex = lazy(() => import('./pages/price'));
const PriceDetail = lazy(() => import('./pages/price/detail'));
const PriceNew = lazy(() => import('./pages/price/new'));
const InventoryIndex = lazy(() => import('./pages/inventory/index_ts'));
const InventoryDetail = lazy(() => import('./pages/inventory/detail_ts'));
const InventoryNew = lazy(() => import('./pages/inventory/new_ts'));
 // const InventoryEdit = lazy(() => import('./pages/inventory/edit'));
const InventoryConfirm = lazy(() => import('./pages/inventory/confirm_ts'));
const InventoryPutAway = lazy(() => import('./pages/inventory/putaway'));
const SalesIndex = lazy(() => import('./pages/sales'));
const OrdersNew = lazy(() => import('./pages/orders/new'));
const OrdersEdit = lazy(() => import('./pages/orders/edit'));
const OrdersIndex = lazy(() => import('./pages/orders'));
const OrdersDetail = lazy(() => import('./pages/orders/detail'));
const InvoicesIndex = lazy(() => import('./pages/invoices/index_ts'));
const InvoicesDetail = lazy(() => import('./pages/invoices/detail_ts'));
const InvoicesNew = lazy(() => import('./pages/invoices/new_ts'));
const SettingIndex = lazy(() => import('./pages/settings/index_ts'));
const SettingDetail = lazy(() => import('./pages/settings/detail'));
const PrintIndex = lazy(() => import('./pages/prints'));
const ActivityIndex = lazy(() => import('./pages/activity/index_ts'));
const ReportingIndex = lazy(() => import('./pages/reporting/index_ts'));
const ItemsIndex = lazy(() => import('./pages/items'));
const ItemsDetail = lazy(() => import('./pages/items/detail'));
const StaticIndex = lazy(() => import('./pages/static'));
const PromotionsIndex = lazy(() => import('./pages/promotions/index_ts'));
const PromotionsNew = lazy(() => import('./pages/promotions/new_ts'));
const PromotionsDetail = lazy(() => import('./pages/promotions/detail_ts'));
const FinancialsIndex = lazy(() => import('./pages/financials'));
const SalesReturnNew = lazy(() => import('./pages/returns/new'));
const SalesReturnIndex = lazy(() => import('./pages/returns'));
const SalesReturnDetail = lazy(() => import('./pages/returns/detail'));
const OthersIndex = lazy(() => import('./pages/others'));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* items page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/items`} element={<ProtectedRoute as='items' />}>
          <Route path='master'>
            <Route path='edit' element={<ItemsEdit sections='master' />} />
            <Route path='new' element={<ItemsNew sections='master' />} />
            <Route path=':id' element={<ItemsDetail sections='master' />} />
            <Route index element={<ItemsIndex sections='master' />} />
          </Route>
          <Route path='listing'>
            <Route path='edit' element={<ItemsEdit sections='listing' />} />
            <Route path='new' element={<ItemsNew sections='listing' />} />
            <Route path=':id' element={<ItemsIndex sections='listing' />} />
          </Route>
          <Route path='bundle'>
            <Route path='edit' element={<ItemsEdit sections='bundle' />} />
            <Route path='new' element={<ItemsNew sections='bundle' />} />
          </Route>
          <Route path='pending' element={<ItemsIndex sections='pending' />} />
          <Route path='cross' element={<ItemsIndex sections='cross' />} />
          <Route path='archived' element={<ItemsIndex sections='archived' />} />
        </Route>

        {/* price page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/price`} element={<ProtectedRoute as='items' />}>
          <Route path='priceadjustment'>
            <Route path='new' element={<PriceNew sections='adjustment' />} />
            <Route path=':id' element={<PriceDetail sections='adjustment' />} />
            <Route index element={<PriceIndex sections='adjustment' />} />
          </Route>
          <Route path='pricehistory' element={<PriceIndex sections='history' />} />
        </Route>

        {/* inventory page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/inventory`} element={<ProtectedRoute as='items' />}>
          <Route path='quantity'>
            <Route path=':id' element={<InventoryDetail sections='quantity' />} />
            <Route index element={<InventoryIndex sections='quantity' />} />
          </Route>
          <Route path='stockadjustment'>
            { isStockStage() && <Route path='confirm/:id' element={<InventoryConfirm sections='adjustment' />} /> }
            <Route path='new' element={<InventoryNew sections='adjustment' />} />
            <Route path=':id' element={<InventoryDetail sections='adjustment' />} />
            <Route index element={<InventoryIndex sections='adjustment' />} />
          </Route>
          <Route path='stockinbound'>
            { isStockStage() && <Route path='putaway/:id' element={<InventoryPutAway sections='inbound' />} /> }
            <Route path='confirm/:id' element={<InventoryConfirm sections='inbound' />} />
            <Route path='new' element={<InventoryNew sections='inbound' />} />
            <Route path=':id' element={<InventoryDetail sections='inbound' />} />
            <Route index element={<InventoryIndex sections='inbound' />} />
          </Route>
          <Route path='stockoutbound'>
            <Route path='confirm/:id' element={<InventoryConfirm sections='outbound' />} />
            <Route path='new' element={<InventoryNew sections='outbound' />} />
            <Route path=':id' element={<InventoryDetail sections='outbound' />} />
            <Route index element={<InventoryIndex sections='outbound' />} />
          </Route>
          <Route path='stocktransfer' >
            <Route path='new' element={<InventoryNew sections='transfer' />} />
            <Route path=':id' element={<InventoryDetail sections='transfer' />} />
            <Route index element={<InventoryIndex sections='transfer' />} />
          </Route>
          <Route path='putaway'>
            <Route path=':id' element={<InventoryDetail sections='putaway' />} />
            <Route index element={<InventoryIndex sections='putaway' />} />
          </Route>
          <Route path='stockhistory' element={<InventoryIndex sections='stockhistory' />} />
          <Route path='daysoutofstock' element={<InventoryIndex sections='daysoutofstock' />} />
          { isStockStage() && (
            <Route path='lowstockalert' element={<InventoryIndex sections='lowstockalert' />} />
          ) }
          { isStockStage() && (
            <Route path='turnover' element={<InventoryIndex sections='turnover' />} />
          ) }
        </Route>

        {/* order page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/orders`} element={<ProtectedRoute as='orders' />}>
          <Route path='salesorders'>
            <Route path=':id' element={<OrdersDetail sections='salesorders' />} />
            <Route index element={<OrdersIndex sections='salesorders' />} />
          </Route>
          <Route path='picklists'>
            <Route path='edit' element={<OrdersEdit sections='picklists' />} />
            <Route path='new' element={<OrdersNew sections='picklists' />} />
            <Route path=':id' element={<OrdersDetail sections='picklists' />} />
            <Route index element={<OrdersIndex sections='picklists' />} />
          </Route>
          <Route path='packages'>
            <Route path='new' element={<OrdersNew sections='packages' />} />
            <Route path=':id' element={<OrdersDetail sections='packages' />} />
            <Route index element={<OrdersIndex sections='packages' />} />
          </Route>
          <Route path='shipments'>
            <Route path=':id' element={<OrdersDetail sections='shipments' />} />
            <Route index element={<OrdersIndex sections='shipments' />} />
          </Route>
          <Route path='sales'>
            <Route path='new' element={<SalesIndex />} />
          </Route>
        </Route>

        {/* invoice page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/invoices`} element={<ProtectedRoute as='orders' />}>
          <Route path='salesinvoices'>
            <Route path=':id' element={<InvoicesDetail sections='salesinvoices' />} />
            <Route index element={<InvoicesIndex sections='salesinvoices' />} />
          </Route>
          <Route path='paymentreceived'>
            <Route path='new' element={<InvoicesNew sections='paymentreceived' />} />
            <Route path=':id' element={<InvoicesDetail sections='paymentreceived' />} />
            <Route index element={<InvoicesIndex sections='paymentreceived' />} />
          </Route>
        </Route>

        {/* promotion page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/promotions`} element={<ProtectedRoute />} >
          <Route path='new' element={<PromotionsNew sections='promotions' />} />
          <Route path=':navbarId/:id' element={<PromotionsDetail sections='promotions' />} />
          <Route path=':navbarId' element={<PromotionsIndex sections='promotions' />} />
        </Route>

        {/* activity page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/activity`} element={<ProtectedRoute />} >
          <Route index element={<ActivityIndex />} />
        </Route>

        {/* financial page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/financials`} element={<ProtectedRoute as='orders' />} >
          <Route path='journalentry' element={<FinancialsIndex sections='journalentry' />} />
        </Route>

        {/* reporting page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/reporting`} element={<ProtectedRoute as='reporting' />}>
          <Route path=':slug/*' element={<ReportingIndex sections='reporting_list'/>} />
          <Route index element={<ReportingIndex sections='reporting' />} />
        </Route>

        {/* return page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/returns/salesreturns`} element={<ProtectedRoute as='orders' />}>
          <Route path='new' element={<SalesReturnNew />} />
          <Route path=':id' element={<SalesReturnDetail />} />
          <Route index element={<SalesReturnIndex />} />
        </Route>

        {/* setting page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/settings/warehouses`} element={<ProtectedRoute />}>
          <Route path=':id' element={<SettingDetail sections='warehouses' />} />
          <Route index element={<SettingIndex sections='warehouses' />} />
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/dashboard/settings/print`} element={<ProtectedRoute />}>
          <Route index element={<SettingIndex sections='print' />} />
        </Route>
        <Route path={`${process.env.PUBLIC_URL}/dashboard/settings`} element={<ProtectedRoute as='settings' />}>
          <Route path='taxes' element={<SettingIndex sections='taxes' />} />
          <Route path='invoices' element={<SettingIndex sections='invoices' />} />
          <Route path='inventory' element={<SettingIndex sections='inventory' />} />
          <Route path='general' element={<SettingIndex sections='general' />} />
        </Route>
        
        {/* print page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/prints`} element={<ProtectedRoute />}>
          <Route path='shipping_labels/:id' element={<PrintIndex sections='shipping_labels' />} />
          <Route path='invoices/:ids' element={<PrintIndex sections='invoices' />} />
          <Route path='picklists/:id' element={<PrintIndex sections='picklists' />} />
          <Route path='packages/:ids' element={<PrintIndex sections='packages' />} />
          <Route path='receipt/:id' element={<PrintIndex sections='receipt' />} />
          <Route path='packlists/:id' element={<PrintIndex sections='packlists' />} />
          <Route path='inbound/:id' element={<PrintIndex sections='inbound' />} />
          <Route path='outbound/:id' element={<PrintIndex sections='outbound' />} />
          <Route path='adjustment/:id' element={<PrintIndex sections='adjustment' />} />
        </Route>
        
        {/* user page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard/users`}>
          <Route path='xyz' element={<UsersIndex />} />
          <Route path='forgot' element={<UsersIndex />} />
          <Route path='login' element={<UsersIndex />} />
          <Route path='verify' element={<UsersIndex />} />
          <Route index element={<UsersIndex />} />
        </Route>

        {/* home page */}
        <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<ProtectedRoute />}>
          <Route path='home' element={<DashboardIndex />} />
          <Route index element={<DashboardIndex />} />
        </Route>

        {/* other page */}
        <Route path={`${process.env.PUBLIC_URL}/static/prints`} element={<StaticIndex sections='prints' />}/>
        <Route path={`${process.env.PUBLIC_URL}/userconfig/:name`} element={<OthersIndex sections='config'/>}/>

        {/* landing page */}
        <Route path={`${process.env.PUBLIC_URL}/`} element={<LandingIndex />} />

        {/* error page */}
        <Route path={`${process.env.PUBLIC_URL}/restricted`} element={<ErrorsIndex section='restricted' />} />
        <Route path='*' element={<ErrorsIndex section='not-found' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;